<script setup>
import logo from '../../img/logo-name.svg'
import menuIcon from '../../img/icons/menuIcon.svg'
import NavigationLink from "@/Components/NavigationLink.vue";
import DropdownLanguage from "@/Components/DropdownLanguage.vue";
import RequestDemo from "@/Components/RequestDemo.vue";
import MobileMenu from "@/Components/MobileMenu.vue";
import AnimatedComponent from "@/Components/AnimatedComponent.vue";
import {nextTick, ref} from "vue";
const scrollTo = (name) => {
  nextTick(() => {
    const element = document.getElementById(name);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  });
};
const isMenuOpen = ref(false)
const closeMenu = () => isMenuOpen.value = false;
const openMenu = () => {
  isMenuOpen.value = true;
}

</script>
<!--add translations-->
<template>
    <div class="pt-8 flex flex-row w-full relative items-end">
      <!-- Medium screen login button positioned in the corner -->
      <a href="/login" class="hidden md:block xl:hidden absolute right-2 bottom-0 bg-main-blue text-main-white text-base rounded-lg py-1.5 px-3 z-10">{{ $t('Log in') }}</a>
      <img @click="openMenu()" class="h-6 absolute mt-1 xl:hidden " :src="menuIcon" alt="logo"/>
      <MobileMenu :is-menu-open="isMenuOpen" :scroll-to="scrollTo" :close-menu="closeMenu"/>
      <a class="mx-auto self-center lg:h-10 xl:mx-0" href="/">
      <img class="h-8 pr-4 my-auto" :src="logo" alt="logo"/>
      </a>
      <animated-component animation-type="fade-top">
        <ul class="hidden ml-auto space-x-3 xl:flex xl:space-x-4 items-center">
          <NavigationLink @click="scrollTo('features')">{{$t('Features')}}</NavigationLink>
          <NavigationLink @click="scrollTo('ourOffer')" >{{$t('Benefits')}}</NavigationLink>
          <NavigationLink @click="scrollTo('howItWorks')">{{$t('Previsit')}}</NavigationLink>
          <NavigationLink @click="scrollTo('aboutUs')">{{$t('About us')}}</NavigationLink>
          <NavigationLink @click="scrollTo('pricing')">{{$t('Pricing')}}</NavigationLink>
          <DropdownLanguage />
          <RequestDemo :title="$t('Request Demo')" :appearance="'button'"/>
          <a href="/login" class="bg-main-blue text-main-white text-lg rounded-lg p-2">{{ $t('Log in') }}</a>-
        </ul>
      </animated-component>
    </div>
</template>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s, transform 0.5s;
  transform-origin: top;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}
.fade-enter-to, .fade-leave-from {
  opacity: 1;
  transform: translateY(0);
}
</style>

